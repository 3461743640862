import React from 'react'
import { useDelete, useFetch, usePost } from '../../../../components/component-items/helpers'
import { BackgroundLayer } from './styles'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import { TableCell } from '../../../../components/component-items/datatable'
import { globalState } from '../../../../store'
import { StatusBoxStyled } from '../../../../components/component-items/status-box'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusHexagon, faStars, faTrash, faTrashAlt } from '@fortawesome/pro-duotone-svg-icons'
import GenericModal from '../../../../components/component-items/modal'
import { AddOnTemplatesProps } from './types'
import { RecurrenceTaskName } from '../../../../enums'

export const AddOnTemplates = ({ categories, open, setOpen }: AddOnTemplatesProps) => {
  const {
    state: { csrf },
  } = globalState()

  const url = `/api/core/recurring-templates/?task_name=${RecurrenceTaskName.TaskCreateBillingTransaction}`
  const { response: resp } = useFetch(url, {})

  const addOnTemplates = resp || []

  return (
    <GenericModal heading={'Select Add Ons'} show={open} size="lg" onHide={() => setOpen(false)}>
      <BackgroundLayer style={{ display: 'block', padding: '1em', minHeight: 400 }}>
        <div style={{ minHeight: 100, width: '100%' }}>
          <GlobalStyles.DataTable>
            <thead>
              <tr>
                <th>
                  <span className="text">Recurrence</span>
                </th>
                <th>
                  <span className="text center">Billing Category</span>
                </th>
                <th>
                  <span className="text center">Add On</span>
                </th>
                <th>
                  <span className="text center">Amount</span>
                </th>
                <th>
                  <span className="text center">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {addOnTemplates.map(
                ({ id, recurrenceDescription, taskKwargs: { category, description, amount } }: any) => (
                  <GlobalStyles.TableRow key={id}>
                    <TableCell>{recurrenceDescription}</TableCell>
                    <TableCell center>{Object.keys(categories).find((n: any) => categories[n] === category)}</TableCell>
                    <TableCell
                      input={
                        <StatusBoxStyled className={'royalshine'} style={{ margin: 'auto', width: 'fit-content' }}>
                          {description}
                        </StatusBoxStyled>
                      }
                    />
                    <TableCell
                      center
                      input={
                        <StatusBoxStyled className={'silver'} style={{ width: 80, margin: 'auto' }}>
                          {'$' + new Intl.NumberFormat('en-US').format(amount || 0)}
                        </StatusBoxStyled>
                      }
                    />
                    <TableCell center>
                      <div
                        className="dropdown__container"
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        <GlobalStyles.Tooltip data-title="Add Recurring Transaction to Merchants Billing Profile">
                          <button onClick={() => usePost(`/api/core/recurring-templates/${id}/add/`, {}, csrf)}>
                            <FontAwesomeIcon icon={faPlusHexagon} />
                          </button>
                        </GlobalStyles.Tooltip>
                        <GlobalStyles.Tooltip data-title="Delete Template">
                          <button onClick={() => useDelete(`/api/core/recurring-templates/${id}/`, csrf)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </GlobalStyles.Tooltip>
                      </div>
                    </TableCell>
                  </GlobalStyles.TableRow>
                )
              )}
            </tbody>
          </GlobalStyles.DataTable>
        </div>
      </BackgroundLayer>
    </GenericModal>
  )
}
