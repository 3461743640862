import React, { useEffect } from 'react'
import { useState } from 'react'

// Components
import { Col, Form } from 'react-bootstrap'
import { getWeekdayNames } from '../../../../../components/component-items/utils'
import { MultiSelect, RecurringSection } from '../styles'
import Select from 'react-select'

export const RecurrenceWeekly = ({ setWeeklyRecurrence }: any) => {
  const [recurrenceWeekday, setRecurrenceWeekday] = useState([{ label: 'Monday', value: 'monday' }])
  const weekNames = getWeekdayNames()
  const weekNamesOptions = weekNames.map((weekDay: string) => ({ label: weekDay, value: weekDay.toLowerCase() }))

  useEffect(() => {
    // convert days of week in names to integers
    const daysOfWeek = recurrenceWeekday.map((day: any) => weekNames.map((a) => a.toLowerCase()).indexOf(day.value))
    var weeklyRecurrence: any = { daysOfWeek: daysOfWeek }
    setWeeklyRecurrence(weeklyRecurrence)
  }, [recurrenceWeekday])

  return (
    <>
      <Form.Group as={Col} xl="9">
        <RecurringSection>
          <div style={{ display: 'flex', alignItems: 'center', padding: '0 0' }}>
            <span style={{ fontWeight: '500', padding: '0 1em', margin: 0 }}>On&nbsp;</span>
            <Form.Group className="required">
              <MultiSelect>
                <Select
                  required
                  options={weekNamesOptions}
                  value={recurrenceWeekday}
                  onChange={setRecurrenceWeekday}
                  closeMenuOnSelect={false}
                  isMulti
                  className="basic-multi-select"
                />
              </MultiSelect>
            </Form.Group>
          </div>
        </RecurringSection>
      </Form.Group>
    </>
  )
}
