import React from 'react'
import GlobalStyles from '../component-items/styles'
import styled from 'styled-components'
import { DropdownStyled } from '../component-items/export-tools'

const TimelineStyled = styled.div`
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  background-color: ${(props) => props.theme.colors.white};
  margin-bottom: 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  position: relative;
  .history-header {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    padding: 0 25px;
    border-bottom: 1px solid #ebedf2;
    min-height: 60px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    .label {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .title {
      margin: 0;
      padding: 0;
      font-size: 1.2rem;
      font-weight: 500;
      color: #48465b;
    }
  }
  .history-body {
    /* padding: 20px 10px 20px 30px; */
    padding: 10px 0;
    min-height: 500px;
    max-height: 60vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bbc2e5;
      border-radius: 5px;
    }
  }
  .history-list {
    position: relative;
    &:before {
      position: absolute;
      display: block;
      content: '';
      width: 1px;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 30px;
      z-index: 5;
      background-color: #ebedf2;
    }
  }
  /* Note this can be refactored */
  button {
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    text-align: center;
    border-radius: 0.2rem;
    &:hover {
      background-color: #e9e9e9;
      svg {
        color: ${(props) => props.theme.colors.dodgerBlue};
      }
    }
  }
`

const TimelineItemStyled = styled.div`
  position: relative;
  display: flex;
  /* align-items: center; */
  padding: 0.5rem 15px 1rem 30px;
  .list__text {
    font-weight: 500;
    width: 100%;
    padding: 0 5px 0 0;
    word-break: break-word;
    color: #646c9a;
  }
  .list__subitem {
    color: rgb(17, 21, 28);
    min-width: 80px;
    text-align: right;
    font-weight: 400;
    padding: 0;
    font-size: 0.8rem;
    text-transform: capitalize;
  }
  .list__username {
    font-weight: 600;
  }
`

const Badge = styled.div`
  display: flex;
  /* align-items: center; */
  text-align: left;
  position: relative;
  width: 25px;
  z-index: 10;
  > div,
  > img {
    position: absolute;
    display: block;
    content: '';
    object-fit: cover;
    object-position: center center;
    width: 32px;
    height: 32px;
    left: -16px;
    top: 6px;
    border-radius: 100%;
  }
  > img {
    border: 2px solid #e1e1e1;
    background-color: white;
  }
  .image-placeholder {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  .loaded-image {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .hide {
    opacity: 0;
  }

  .show {
    opacity: 1;
  }
`

const Unread = styled.span`
  position: absolute;
  color: #ff862b;
  left: -17px;
  top: 5px;
  width: 10px;
  height: 10px;
  background-color: #ff862b;
  border: 1px solid #fff;
  border-radius: 100%;
`

const Online = styled.span`
  position: absolute;
  left: 4px;
  top: 27px;
  width: 14px;
  height: 14px;
  background-color: #5d78ff;
  border: 1.5px solid #fff;
  border-radius: 100%;
`

const Offline = styled(Online)`
  background-color: #e1e1e1;
`

const Box = styled.div`
  position: absolute;
  left: -10px;
  top: 0px;
`

const Ellipsis = styled.div`
  font-size: 2em;
  color: #6b7992;
  /* display: inline-block; */
  position: absolute;
  top: -11px;
  right: 22px;
  .ellipsis_container {
    position: relative;
    height: 20px;
    width: 20px;
    top: 16px;
    /* right: 10px; */
  }
  .ellipsis_container:hover {
    user-select: none;
    cursor: pointer;
    background-color: rgb(244, 245, 251);
    border-radius: 5px;
  }
  .ellipsis_text {
    position: absolute;
    top: -17px;
    right: 2px;
    font-weight: 400;
  }
`
const Dropdown = styled(DropdownStyled)`
  margin: 0 !important;
  padding: 5px 0 !important;
  top: 0;
  right: 105%;
`

const Tooltip = styled(GlobalStyles.Tooltip)`
  &:before {
    width: 100px;
    left: 50%;
    transform: translate(-50%, 0);
  }
`

const NotificationTag = styled.div`
  cursor: default;
  user-select: none;
  font-size: 0.85rem;
  font-weight: 600;
  align-items: center;
  font-size: 0.875rem;
  border-radius: 0.2rem;
  display: inline-block;
  padding: 0 .3em;
  margin: 5px .5em .5em 0;
  font-weight: 0.8em;
  line-height: 1.4em;
  opacity: 80;
  border: 1px solid;
  background-color: ${(props) => props.color + '1A'};
  color: ${(props) => props.color};
  }
`

const TextBox = styled.div`
  border: 1.5px solid #e1e1e1;
  border-radius: 7px;
  padding: 0.5em 0.7em;
  color: #585858;
  font-size: 12px;
  line-height: 1.4em;
`

const FileBox = styled(TextBox)`
  transition: background-color 0.2s ease-in-out;
  .file:hover {
    cursor: pointer;
    user-select: none;
    background-color: #dedff0;
    border-radius: 7px;
    transition: background-color 0.2s ease-in-out;
  }
`

const Quote = styled.div`
  padding-left: 1em;
  margin-left: 1em;
  border-left: 2px solid #e4e4e4;
  margin-top: 5px;
  color: #585858;
  font-size: 12px;
`

export const NoneLeft = styled.div`
  width: 100%;
  font-size: 1.5em;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  svg {
    margin-bottom: 10px;
    font-size: 2em;
  }
`

export const NotificationsStyled = {
  NotificationTag,
  TextBox,
  FileBox,
  Quote,
  TimelineStyled,
  TimelineItemStyled,
  Badge,
  Unread,
  Online,
  Offline,
  Box,
  Ellipsis,
  Dropdown,
  Tooltip,
  NoneLeft,
}
export default NotificationsStyled
