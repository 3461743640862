import styled from 'styled-components'
import GlobalStyles from '../../components/component-items/styles'

export const TableRow = styled(GlobalStyles.TableRow)`
  p {
    max-width: fit-content;
    margin-bottom: 0;
  }
`

export const FilterListItem = styled.div`
  margin: 0 auto 1em;
  width: 50%;
  &.large {
    width: 75%;
  }
`

export const DateRangeStyled = styled.div`
  overflow: scroll;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.19);
  margin: 2em auto;
  width: fit-content;
`

export const MultiSelect = styled.div`
  .basic-multi-select > div {
    min-height: 40px;
    div[id^='react-select-3-option'] {
      &:hover {
        background-color: #deebff;
      }
      :not(:hover) {
        transition: background-color 0.1s linear;
        -webkit-transition: background-color 0.1s linear;
      }
    }
  }
  div[id^='react-select-3']:not(:hover) {
    background-color: transparent;
  }
  div[id^='react-select-'][id$='-group-'][id*='-heading']:not(:hover) {
    background-color: transparent;
  }
  div[id^='react-select-3-group']:hover + div > div {
    background-color: #deebff !important;
  }
`

export const HeaderHover = styled.div`
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
`
