import React from 'react'
import { useDelete, useFetch } from '../../../../components/component-items/helpers'
import { BackgroundLayer } from './styles'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import { TableCell } from '../../../../components/component-items/datatable'
import { globalState } from '../../../../store'
import { StatusBoxStyled } from '../../../../components/component-items/status-box'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStars, faTrashAlt } from '@fortawesome/pro-duotone-svg-icons'
import { RecurrenceTaskName } from '../../../../enums'

export const AddOns = () => {
  const {
    state: { userIsExecutive, csrf },
  } = globalState()

  const url = `/api/core/recurring-tasks/?task_name=${RecurrenceTaskName.TaskCreateBillingTransaction}`
  const { response: resp } = useFetch(url, {})

  const addons = resp || []
  const total = resp?.length || 0

  return (
    total > 0 && (
      <BackgroundLayer style={{ display: 'block', padding: '1em', minHeight: 200 }}>
        <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
          <GlobalStyles.CardTitle>
            <h3>
              <FontAwesomeIcon icon={faStars} style={{ color: '#001682', marginRight: '.5em' }} />
              Add-Ons<small>{total} Total</small>
            </h3>
          </GlobalStyles.CardTitle>
          <GlobalStyles.CardToolbar></GlobalStyles.CardToolbar>
        </GlobalStyles.CardHeader>
        <div style={{ minHeight: 100, width: '100%' }}>
          <GlobalStyles.DataTable>
            <thead>
              <tr>
                <th>
                  <span className="text">Recurrence</span>
                </th>
                <th>
                  <span className="text center">Add On</span>
                </th>
                <th>
                  <span className="text center">Amount</span>
                </th>
                {userIsExecutive && (
                  <th>
                    <span className="text center">Actions</span>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {addons.map(({ id, recurrenceDescription, taskKwargs: { description, amount } }: any) => (
                <GlobalStyles.TableRow key={id}>
                  <TableCell>{recurrenceDescription}</TableCell>
                  <TableCell
                    input={
                      <StatusBoxStyled className={'royalshine'} style={{ margin: 'auto', width: 'fit-content' }}>
                        {description}
                      </StatusBoxStyled>
                    }
                  />
                  <TableCell
                    center
                    input={
                      <StatusBoxStyled className={'silver'} style={{ width: 80, margin: 'auto' }}>
                        {'$' + new Intl.NumberFormat('en-US').format(amount || 0)}
                      </StatusBoxStyled>
                    }
                  />
                  {userIsExecutive && (
                    <TableCell center>
                      <div className="dropdown__container">
                        <button onClick={() => useDelete(`/api/core/recurring-tasks/${id}/`, csrf)}>
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </div>
                    </TableCell>
                  )}
                </GlobalStyles.TableRow>
              ))}
            </tbody>
          </GlobalStyles.DataTable>
        </div>
      </BackgroundLayer>
    )
  )
}
