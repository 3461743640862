import React, { useEffect, useRef, useState } from 'react'
import { notify, usePost } from '../../../../components/component-items/helpers'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import GenericModal from '../../../../components/component-items/modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { RecurringGroup, RecurringSchedule, RecurringSection, RoyalShineButton, SubHeader } from './styles'

// styled components
import { Col } from 'react-bootstrap'

import { AddRecurringTransactionProps } from './types'
import { EndRecurrence } from './ending'
import { RecurrenceYearly } from './recurrence/yearly'
import { RecurrenceMonthly } from './recurrence/monthly'
import { RecurrenceWeekly } from './recurrence/weekly'
import { recurrenceSummary } from './recurrence/summary'
import { StatusBarStyled } from '../../../../components/component-items/status-bar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoSquare } from '@fortawesome/pro-duotone-svg-icons'
import { globalState } from '../../../../store'
import { BillingStatus, RecurrenceCategory, RecurrenceTaskName } from '../../../../enums'

export const AddRecurringTransaction = ({ open, setOpen, categories }: AddRecurringTransactionProps) => {
  const {
    state: { csrf },
  } = globalState()

  // Form data
  const node = useRef<HTMLFormElement>(null)
  const [validated, setValidated] = useState(false)

  // Recurrence Interval Section
  const [recurrenceInterval, setRecurrenceInterval] = useState(1)
  const [scheduleType, setScheduleType] = useState('daily')
  const scheduleOptions = [
    { label: 'Hourly', value: 'hourly', plural: 'hour(s)', singular: 'hour' },
    { label: 'Daily', value: 'daily', plural: 'day(s)', singular: 'day' },
    { label: 'Weekly', value: 'weekly', plural: 'week(s)', singular: 'week' },
    { label: 'Monthly', value: 'monthly', plural: 'month(s)', singular: 'month' },
    { label: 'Yearly', value: 'yearly', plural: 'year(s)', singular: 'year' },
  ]

  const [yearlyRecurrence, setYearlyRecurrence] = useState({})
  const [monthlyRecurrence, setMonthlyRecurrence] = useState({})
  const [weeklyRecurrence, setWeeklyRecurrence] = useState({})
  const [endRecurrence, setEndRecurrence] = useState({})

  // Transaction Details Section
  const [transactionCategory, setTransactionCategory] = useState<string | undefined>('N')
  const [transactionAmount, setTransactionAmount] = useState<string>('100')
  const [transactionDescription, setTransactionDescription] = useState<string>('')

  const [summary, setSummary] = useState<any>()
  useEffect(() => {
    setSummary(
      recurrenceSummary({
        recurrenceInterval,
        scheduleType,
        scheduleOptions,
        transactionAmount,
        ...yearlyRecurrence,
        ...monthlyRecurrence,
        ...weeklyRecurrence,
        ...endRecurrence,
      })
    )
  }, [
    recurrenceInterval,
    scheduleType,
    transactionAmount,
    yearlyRecurrence,
    monthlyRecurrence,
    weeklyRecurrence,
    endRecurrence,
  ])

  async function handleSubmit(event: any, url: string) {
    event.preventDefault()
    event.stopPropagation()

    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      notify({ type: 'error', title: 'Missing Information', message: 'Please fill out the entire form!' })
      return
    }

    var recurrenceData = {
      category: RecurrenceCategory.TypeBilling,
      taskName: RecurrenceTaskName.TaskCreateBillingTransaction,
      recurrenceDescription: summary.textOutput,
      taskKwargs: {
        category: transactionCategory,
        amount: transactionAmount,
        description: transactionDescription,
      },
      scheduleType: scheduleType,
      recurrenceInterval: recurrenceInterval,
      ...endRecurrence,
    }
    if (scheduleType === 'yearly') {
      recurrenceData = { ...recurrenceData, ...yearlyRecurrence }
    } else if (scheduleType === 'monthly') {
      recurrenceData = { ...recurrenceData, ...monthlyRecurrence }
    } else if (scheduleType === 'weekly') {
      recurrenceData = { ...recurrenceData, ...weeklyRecurrence }
    }

    let response = await usePost(url, recurrenceData, csrf)
    if (response && response.success) {
      notify({ type: 'success', message: 'New Recurring Transaction Added!' })
      setOpen(false)
    }
  }

  return (
    <GenericModal
      heading={'Add Recurring Transaction'}
      show={open}
      size="lg"
      onHide={() => setOpen(false)}
      buttons={
        <>
          <GlobalStyles.Button className="secondary" onClick={(e) => handleSubmit(e, '/api/core/recurring-templates/')}>
            Create Template
          </GlobalStyles.Button>
          <RoyalShineButton className="royal" onClick={(e) => handleSubmit(e, '/api/core/recurring-tasks/')}>
            Add Recurring Transaction
          </RoyalShineButton>
        </>
      }
    >
      <Form noValidate validated={validated} ref={node} onSubmit={(e) => handleSubmit(e, '/api/core/recurring-tasks/')}>
        <RecurringGroup>
          <Form.Group as={Col} xl="9">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <RecurringSchedule aria-label="Reccurence">
                {scheduleOptions.map(({ label, value }, id: number) => (
                  <Button
                    key={id}
                    variant="secondary"
                    active={scheduleType == value}
                    onClick={() => setScheduleType(value)}
                  >
                    {label}
                  </Button>
                ))}
              </RecurringSchedule>
            </div>
          </Form.Group>
          <Form.Group as={Col} xl="9">
            <SubHeader style={{ paddingTop: '2em' }}>
              Repeat every&nbsp;
              <Form.Control
                required
                type="number"
                isInvalid={!recurrenceInterval || recurrenceInterval < 1}
                value={recurrenceInterval}
                onChange={(e) => setRecurrenceInterval(parseInt(e.target.value))}
                style={{ width: 100, margin: '0 1em' }}
              />
              {scheduleOptions.find(({ value }) => value === scheduleType)?.plural || ''}
            </SubHeader>
          </Form.Group>
          {scheduleType === 'yearly' ? (
            <RecurrenceYearly {...{ setYearlyRecurrence }} />
          ) : scheduleType === 'monthly' ? (
            <RecurrenceMonthly {...{ setMonthlyRecurrence }} />
          ) : scheduleType === 'weekly' ? (
            <RecurrenceWeekly {...{ setWeeklyRecurrence }} />
          ) : null}
          <Form.Group as={Col} xl="9">
            <p style={{ paddingTop: '1em' }}>
              Multiple recurring transactions may be required if they span multiple intervals
            </p>
          </Form.Group>
          <EndRecurrence {...{ setEndRecurrence }} />
          <Form.Group as={Col} xl="9">
            <SubHeader>Transaction Details</SubHeader>
            <RecurringSection>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                <Form.Group>
                  <Form.Label style={{ margin: '0 .25em', fontWeight: 500 }}>Category</Form.Label>
                  <Form.Select value={transactionCategory} onChange={(e) => setTransactionCategory(e.target.value)}>
                    {Object.keys(categories)
                      .filter((c: any) => ![BillingStatus.Payment, BillingStatus.Credit].includes(categories[c]))
                      .map((k: any, id) => (
                        <option value={categories[k]} key={id}>
                          {k}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group>
                  <Form.Label style={{ margin: '0 0 0 .75em', fontWeight: 500 }}>Amount</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    isInvalid={!transactionAmount || parseInt(transactionAmount) < 1}
                    value={transactionAmount}
                    onChange={(e) => setTransactionAmount(e.target.value)}
                    style={{ width: 100, margin: '0 0 0 .5em' }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label style={{ margin: '0 0 0 .75em', fontWeight: 500 }}>Description</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    value={transactionDescription}
                    onChange={(e) => setTransactionDescription(e.target.value)}
                    style={{ width: 240, margin: '0 0 0 .5em' }}
                  />
                </Form.Group>
              </div>
            </RecurringSection>
          </Form.Group>
          <Form.Group as={Col} xl="9">
            <SubHeader style={{ paddingTop: '2em' }}>Summary</SubHeader>
            <StatusBarStyled
              style={{ backgroundColor: '#f4f7ff', marginBottom: 0, borderRadius: '5px', border: '1px solid #c7c9e5' }}
            >
              <div className="header-item">
                <div className="status-icon">
                  <FontAwesomeIcon icon={faInfoSquare} style={{ color: '#800080' }} />
                </div>
                <div className="status-text">{summary?.htmlOutput}</div>
              </div>
            </StatusBarStyled>
          </Form.Group>
        </RecurringGroup>
      </Form>
    </GenericModal>
  )
}
