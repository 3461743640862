import React, { useEffect } from 'react'
import { useState } from 'react'

// Components
import { Col, Form } from 'react-bootstrap'
import { RadioButton } from '../../../../../components/component-items/radio-button'
import { getMonthNames, getOrdinal, getWeekdayNames } from '../../../../../components/component-items/utils'
import { RecurringSection } from '../styles'

export const RecurrenceYearly = ({ setYearlyRecurrence }: any) => {
  const [recurrenceDateType, setRecurrenceDateType] = useState('day-of-month')

  const [dayOfMonth, setDayOfMonth] = useState<number>(0)
  const dayOfMonthOptions: number[] = Array.from({ length: 31 }, (_, i) => i + 1)

  const [monthOption1, setMonthOption1] = useState<number>(0)
  const [monthOption2, setMonthOption2] = useState<number>(0)
  const monthNames = getMonthNames()

  const [dayOfWeek, setDayOfWeek] = useState<number>(1)
  const dayOfWeekOptions: number[] = Array.from({ length: 7 }, (_, i) => i)
  const weekNames = getWeekdayNames()

  const weekSegmentOptions = ['First', 'Second', 'Third', 'Fourth']
  const [weekSegment, setWeekSegment] = useState(weekSegmentOptions[0])

  useEffect(() => {
    var yearlyRecurrence: any = { recurrenceDateType: recurrenceDateType }
    if (recurrenceDateType === 'day-of-month') {
      yearlyRecurrence['dayOfMonth'] = dayOfMonth
      yearlyRecurrence['monthOfYear'] = monthOption1
    } else if (recurrenceDateType === 'month-segment') {
      yearlyRecurrence['weekSegment'] = weekSegment
      yearlyRecurrence['dayOfWeek'] = dayOfWeek
      yearlyRecurrence['monthOfYear'] = monthOption2
    }
    setYearlyRecurrence(yearlyRecurrence)
  }, [dayOfMonth, monthOption1, recurrenceDateType, weekSegment, dayOfWeek, monthOption2])

  return (
    <>
      <Form.Group as={Col} xl="9">
        <RecurringSection>
          <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => setRecurrenceDateType('day-of-month')}>
            <RadioButton
              name="recurrenceDateOptions"
              value="day-of-month"
              checked={recurrenceDateType === 'day-of-month'}
              onChange={(e: any) => setRecurrenceDateType(e.target.value)}
            />
            <span style={{ fontWeight: '500', padding: '0 1em' }}>On the&nbsp;</span>
            <Form.Select
              value={dayOfMonth}
              onChange={(e) => setDayOfMonth(parseInt(e.target.value))}
              style={{ width: 140 }}
            >
              {dayOfMonthOptions.map((day: number, id: number) => (
                <option value={day} key={id}>
                  {getOrdinal(day)}
                </option>
              ))}
            </Form.Select>
            <span style={{ fontWeight: '500', padding: '0 1em' }}>&nbsp;of&nbsp;</span>
            <Form.Select
              value={monthOption1}
              onChange={(e) => setMonthOption1(parseInt(e.target.value))}
              style={{ width: 150 }}
            >
              {monthNames.map((monthName, id: number) => (
                <option value={id} key={id}>
                  {monthName}
                </option>
              ))}
            </Form.Select>
          </div>
          <div
            style={{ display: 'flex', alignItems: 'center', padding: '2em 0 0' }}
            onClick={() => setRecurrenceDateType('month-segment')}
          >
            <RadioButton
              name="recurrenceDateOptions"
              value="month-segment"
              checked={recurrenceDateType === 'month-segment'}
              onChange={(e: any) => setRecurrenceDateType(e.target.value)}
            />
            <span style={{ fontWeight: '500', padding: '0 1em' }}>On the&nbsp;</span>
            <Form.Select
              value={weekSegment}
              onChange={(e) => setWeekSegment(e.target.value)}
              style={{ width: 100, marginRight: '.5em' }}
            >
              {weekSegmentOptions.map((weekNumber: string, id: number) => (
                <option value={weekNumber} key={id}>
                  {weekNumber}
                </option>
              ))}
            </Form.Select>
            <Form.Select
              value={dayOfWeek}
              onChange={(e) => setDayOfWeek(parseInt(e.target.value))}
              style={{ width: 120 }}
            >
              {dayOfWeekOptions.map((day: number, id: number) => (
                <option value={day} key={id}>
                  {weekNames[day]}
                </option>
              ))}
            </Form.Select>
            <span style={{ fontWeight: '500', padding: '0 1em' }}>&nbsp;of&nbsp;</span>
            <Form.Select
              value={monthOption2}
              onChange={(e) => setMonthOption2(parseInt(e.target.value))}
              style={{ width: 120 }}
            >
              {monthNames.map((monthName, id: number) => (
                <option value={id} key={id}>
                  {monthName}
                </option>
              ))}
            </Form.Select>
          </div>
        </RecurringSection>
      </Form.Group>
    </>
  )
}
